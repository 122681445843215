:root {
  --primary: #1930ab !important;
  --secondary: #00b0f0 !important;
  --text: #252525 !important;
}

.primary {
  color: var(--primary) !important;
}
.secondary {
  color: var(--secondary) !important;
}
.text {
  color: var(--text) !important;
}
/* Text Allignments */
.center {
  text-align: center !important;
}

.right {
  text-align: right !important;
}

.left {
  text-align: left !important;
}

.justify {
  text-align: justify !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.w100 {
  width: 100% !important;
}

/* Inline Allignment */
.flex {
  display: flex !important;
}

.fx_fs {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.fx_fe {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.fx_sb {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.fx_c {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.fx_se {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

/* Inline Allignment */

/* Spacing GAP */
.gap1 {
  gap: 8px !important;
}

.gap2 {
  gap: 16px !important;
}

.white-card {
  border-radius: 8px !important;
  padding: 20px !important;
  background-color: white !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

/* Switch */
.simple-switch {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.simple-switch .MuiFormControlLabel-root {
  margin: 0px !important;
}

.new-select .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(0px, 6px) scale(0.75);
}

.new-select .MuiInputLabel-filled {
  transform: translate(0px, 30px) scale(1);
}

.new-select .MuiInput-root {
  font-size: 13px !important;
}

.new-select .MuiListItemText-primary {
  font-size: 13px !important;
}

.new-select .MuiListItemText-primary {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.new-select .MuiCheckbox-root {
  padding: 0px !important;
  padding-right: 6px !important;
}
.new-select .MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #f2f2f2 !important;
}

.new-select .MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--primary) !important;
}
/* Pageheadings css started */
.heading {
  font-size: 20px !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
}
.heading2 {
  font-size: 16px !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
}
.back-text-btn {
  font-size: 13px !important;
  text-transform: none !important;
  color: var(--text) !important;
}
.back-text-btn .MuiSvgIcon-root {
  font-size: 15px !important;
}
.back-text-btn:hover {
  background-color: transparent !important;
  color: var(--primary) !important;
}

.brd-btm {
  padding-bottom: 6px !important;
  border-bottom: 2px solid var(--primary) !important;
}

/* Buttons css started */
.btn-2 {
  background-color: var(--secondary) !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 4px 16px !important;
}

.btn-1 {
  background-color: var(--primary) !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 4px 16px !important;
}

.btn-primary {
  background-color: var(--primary) !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 4px 16px !important;
  text-transform: none !important;
}

.btn-secondary {
  background-color: var(--secondary) !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 4px 16px !important;
  text-transform: none !important;
}

.btn-tertiary {
  background-color: #d9d9d9 !important;
  color: var(--text) !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 4px 16px !important;
  text-transform: none !important;
}

.btn-back {
  background-color: #d9d9d9 !important;
  color: var(--text) !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 4px 16px !important;
  text-transform: none !important;
}

.MuiButton-contained.Mui-disabled {
  opacity: 0.3 !important;
}

/* datagrid design */
#new-datagrid .MuiDataGrid-root {
  border: none !important;
  line-height: normal !important;
}

#new-datagrid .MuiDataGrid-toolbarContainer {
  justify-content: flex-end !important;
}

#new-datagrid .MuiDataGrid-toolbarContainer .MuiButton-root {
  /* font-size: 13px !important;
  line-height: normal !important;
  font-weight: 500 !important;
  padding: 4px 12px !important;
  background-color: var(--text) !important;
  color: #fff !important;
  margin-bottom: 12px !important; */

  font-size: 12px !important;
  line-height: normal !important;
  font-weight: 500 !important;
  padding: 4px 14px !important;
  background-color: #d9d9d9 !important;
  color: black;
  margin-bottom: 12px !important;
  text-transform: none;
  border-radius: 16px !important;
}
/* .MuiDataGrid-columnHeaderRow{
  background-color: #f2f2f2 !important;
} */
#new-datagrid .MuiDataGrid-columnHeaders {
  height: auto !important;
  min-height: auto !important;
}
#new-datagrid .MuiDataGrid-columnHeader {
  background-color: #f2f2f2 !important;
  height: auto !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
#new-datagrid .MuiDataGrid-columnHeaderTitleContainerContent {
  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 600 !important;
  padding: 12px 4px !important;
  width: 100% !important;
}

#new-datagrid .MuiDataGrid-footerContainer {
  min-height: auto !important;
  background-color: #f2f2f2 !important;
  padding: 4px 0px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

#new-datagrid .MuiDataGrid-footerContainer .MuiTablePagination-toolbar {
  min-height: auto !important;
  font-size: 13px !important;
}

#new-datagrid .MuiTablePagination-actions .MuiIconButton-root {
  padding: 4px !important;
}

#new-datagrid .MuiTablePagination-actions .MuiIconButton-root .MuiSvgIcon-root {
  font-size: 20px !important;
}
#new-datagrid .MuiTablePagination-displayedRows,
#new-datagrid .MuiTablePagination-selectLabel {
  margin: 0px !important;
  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 400 !important;
}

/* Project Card UI */
.project-card {
  background-color: #fff !important;
  border-radius: 16px !important;
  padding: 25px !important;
  transition: all 0.2s ease-in-out !important;
  text-align: center !important;
}

.project-card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.project-name {
  font-size: 18px !important;
  line-height: normal !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  text-align: center !important;
  color: var(--primary) !important;
}

.project-img {
  width: 125px !important;
  height: auto !important;
  opacity: 0.5 !important;
  user-select: none !important;
}

.action-1 {
  background-color: #303f9f44 !important;
  color: var(--primary) !important;
}

.action-1:hover {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.action-2 {
  background-color: #00b0f032 !important;
  color: var(--secondary) !important;
}

.action-2:hover {
  background-color: var(--secondary) !important;
  color: #fff !important;
}

/* MUI DataTable css Started */
#new-muitable .MuiTableRow-head .MuiButtonBase-root {
  width: 100% !important;
  justify-content: flex-start !important;
  margin: 0px !important;
  /* padding: 12px 14px !important; */

  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 600 !important;
  padding: 12px 14px !important;
  width: 100% !important;
}

#new-muitable .MuiTableRow-head .MuiButtonBase-root .MuiButton-label div {
  align-items: center !important;
}

#new-muitable .MuiTableCell-head {
  background-color: #f2f2f2 !important;
  padding: 0px !important;
}

#new-muitable .MuiPaper-root {
  box-shadow: none !important;
  background-color: transparent !important;
}

#new-muitable .MuiTableRow-footer {
  background-color: #f2f2f2 !important;
}

#new-muitable .MuiTableCell-footer {
  border-bottom: none !important;
}

#new-muitable .MuiTablePagination-caption {
  margin: 0px !important;
  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 400 !important;
}

#new-muitable .MuiTablePagination-actions .MuiIconButton-root {
  padding: 4px !important;
}

#new-muitable .MuiTablePagination-actions .MuiIconButton-root .MuiSvgIcon-root {
  font-size: 20px !important;
}
#new-muitable .MuiTablePagination-toolbar {
  min-height: auto !important;
  padding: 4px 0px !important;
}

#new-muitable tbody .MuiTableCell-root {
  padding: 6px 12px !important;
  font-size: 13px !important;
  line-height: normal !important;
  height: 38px !important;
}

#new-muitable .MuiPaper-root > .MuiToolbar-regular .MuiIconButton-root {
  padding: 6px !important;
  background-color: #192fab24 !important;
  border-radius: 4px !important;
  margin-left: 10px !important;
  margin-bottom: 6px !important;
}

#new-muitable .MuiToolbar-root .MuiIconButton-root .MuiSvgIcon-root {
  font-size: 18px !important;
}

#new-muitable .MuiToolbar-root {
  padding: 0px !important;
  min-height: auto !important;
}

#new-muitable .MuiTableCell-footer {
  padding: 6px 0px !important;
}

#new-muitable .MuiTableCell-head > div {
  width: 100% !important;
  justify-content: flex-start !important;
  margin: 0px !important;
  padding: 12px 14px !important;
  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 600 !important;
}

.new-textfield .MuiInputBase-root {
  font-size: 13px !important;
  line-height: normal !important;
}
.new-textfield .MuiFormLabel-root {
  font-size: 13px !important;
  line-height: normal !important;
}

.new-textfield .MuiFormControl-root {
  width: 100% !important;
}

.new-textfield.new-select label + .MuiInput-formControl {
  margin-top: 0px !important;
}

.dialog-close {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}

#new-tabs .MuiTab-root {
  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 500 !important;
  background-color: #fff !important;
  min-height: auto !important;
  padding: 10px 12px !important;
  margin-right: 12px !important;
  border-radius: 16px !important;
  /* border-top-left-radius: 16px !important; */
  /* border-top-right-radius: 16px !important; */
}

#new-tabs .MuiTabs-root {
  min-height: auto !important;
}

#new-tabs .MuiTabs-indicator {
  display: none !important;
}
#new-tabs .Mui-selected {
  background-color: var(--secondary) !important;
  color: white !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

#new-dropzone .MuiDropzoneArea-root {
  background-color: #f2f2f2 !important;
  min-height: 200px !important;
  display: grid !important;
  place-items: center !important;
}

#new-dropzone .MuiDropzoneArea-text {
  font-size: 16px !important;
  line-height: normal !important;
  font-weight: 500 !important;
  color: var(--text) !important;
}

#new-dropzone .MuiDropzoneArea-icon {
  color: var(--primary) !important;
}

/* DIalog title Icon */
.MuiDialogTitle-root {
  padding-bottom: 6px !important;
}
.MuiDialogTitle-root .MuiIconButton-root {
  padding: 4px !important;
}

/* Article Link List */
.article-links .primary-txt {
  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 500 !important;
  color: var(--primary) !important;
}

.article-links .MuiListItemAvatar-root .MuiButtonBase-root {
  background-color: #192fab42 !important;
  color: var(--primary) !important;
  border-radius: 4px !important;
}
.article-links .MuiListItemSecondaryAction-root {
  right: 10px !important;
}

.article-links .MuiListItemSecondaryAction-root .MuiButtonBase-root {
  color: var(--primary) !important;
  /* background-color: #00b0f037 !important; */
  background-color: #f2f2f2 !important;
  border-radius: 4px !important;
}

/*  */
.MuiToolbar-root h6 {
  font-size: 16px !important;
  line-height: normal !important;
  color: black !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

.modal-close {
  right: 8px !important;
  top: 8px !important;
  position: absolute !important;
}

.new-textfield .MuiChip-root {
  font-size: 12px !important;
  padding: 3px 2px !important;
  height: auto !important;
}
.new-textfield .MuiChip-root .MuiSvgIcon-root {
  font-size: 18px !important;
}

.body1 {
  font-size: 13px !important;
}

[data-popper-placement*="bottom"] {
  margin-top: 0px !important;
}
[data-popper-placement*="top"] {
  margin-bottom: 0px !important;
}
[data-popper-placement*="right"] {
  margin-left: 0px !important;
}
[data-popper-placement*="left"] {
  margin-right: 0px !important;
}

@media (max-width: 725px) {
  #project-list-buttons .MuiStack-root {
    display: block !important;
  }
  #project-list-buttons .MuiStack-root .btn-1 {
    margin: 4px 4px !important;
  }
  #project-list-buttons .MuiStack-root .btn-2 {
    margin: 4px 4px !important;
  }
  #project-list-buttons .MuiStack-root a {
    margin-left: 0px !important;
  }
  #project-list-buttons .MuiStack-root button {
    margin-left: 0px !important;
    margin: 0px 4px !important;
  }
}


#new-datagrid .MuiDataGrid-virtualScroller {
  margin-top: 40px !important;
}

#new-datagrid .row-content{
  font-size: 13px !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
#new-datagrid .MuiDataGrid-cell {
  font-size: 13px !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.newsbodydate{
  font-size: 13px !important;
  line-height: normal !important;
  font-weight: 500 !important;
}
.date-icon {
display: flex !important;
}
.date-icon .MuiSvgIcon-fontSizeSmall{
font-size: 15px !important;
}